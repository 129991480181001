import axios from "axios"
import appConfig from "../../configs/appConfig";
import {parseKendoGridDataState} from "../components/utils";

const comunicationPath = appConfig.API.comunications


const getComunications = async (
	translateHook,
	i18n,
	dataState,
	exportScope = false,
	cancelDataToken = undefined,
	to_read = null
) => {

	let parseDataState = '',
        response_comunication,
		comunications
    if (exportScope)
		parseDataState = parseKendoGridDataState(dataState, `${to_read !== null ? `?to_read=${to_read}&skip=0` : '?skip=0'}`)
	 else {
        // dataState = checkEmptyFields(dataState, translateHook)
        parseDataState = parseKendoGridDataState(dataState, `${to_read !== null ? `?to_read=${to_read}` : ''}`)
    }
	try {
		response_comunication = await axios.get(comunicationPath + parseDataState, cancelDataToken ? cancelDataToken : {})
		comunications = response_comunication.data.results
		return Promise.resolve({
			comunications,
			count: response_comunication.data.count
		})
	} catch (e) {
		return Promise.reject(new Error(`Exception Fetching comunication from API ${e}`))
	}
}

const getComunication = async (comunicationId) => {
	return axios.get(`${comunicationPath}/${comunicationId}`)
}

const updateComunication = async (comunicationId, data) => {
	return axios.put(`${comunicationPath}/${comunicationId}`, data)
}

const createComunication = async (data) => {
	return axios.post(`${comunicationPath}`, data)
}

const deleteComunication = async (comunicationId) => {
	return axios.delete(`${comunicationPath}/${comunicationId}`)
}

export {
	getComunication,
	getComunications,
	updateComunication,
	deleteComunication,
	createComunication
}